import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import styled, { withTheme } from 'styled-components/macro'
import { updateActiveMembership } from '../../redux/slices/projects'
import {
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Button as MuiButton
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { spacing } from '@mui/system'
import { ActiveProject } from '../../types'

const Button = styled(MuiButton)(spacing)
// eslint-disable-next-line
function NavbarProjectsDropdown() {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const activeProjects = useSelector(
    (state: RootState) => state.projects.activeProjects
  )
  const handleChange = (event: SelectChangeEvent) => {
    //PASS LONG ID HERE
    dispatch(updateActiveMembership(event.target.value))
  }

  //Filter soft deleted projects out
  const filteredActiveProjects = activeProjects.filter(
    (item) => item.deletedAt === null
  )

  return (
    <React.Fragment>
      {activeProjects && (
        <FormControl sx={{ m: 3, minWidth: 120 }} size="small">
          <InputLabel id="select-active-project-label">
            {t('Navbar.project')}
          </InputLabel>
          {currentUser?.activeProjectMembership ? (
            <Select
              fullWidth
              id="select-active-project"
              labelId="select-active-project-label"
              value={currentUser?.activeProjectMembership.id}
              defaultValue={currentUser?.activeProjectMembership.id}
              label={t('Navbar.project')}
              onChange={handleChange}
            >
              {filteredActiveProjects.map((project: ActiveProject) => (
                <MenuItem
                  key={project.membership?.id}
                  value={project.membership?.id}
                >
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              id="select-active-project"
              labelId="select-active-project-label"
              value={''}
              label={t('Navbar.project')}
              onChange={handleChange}
            >
              {activeProjects.map((project: ActiveProject) => (
                <MenuItem
                  key={project.membership?.id}
                  value={project.membership?.id}
                >
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      )}
    </React.Fragment>
  )
}

export default NavbarProjectsDropdown
