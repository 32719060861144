import { createRef, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Alert, Box, Grid, useMediaQuery } from '@mui/material'
import useAuth from '../hooks/useAuth'

import AssetGridSkeleton from './AssetGridSkeleton'
import {
  Asset,
  AssetGridViewMode,
  AssetQuery,
  Project,
  Statistic
} from '../types'
import { useTranslation } from 'react-i18next'
import AssetGrid from './AssetGrid'
import { getAssetsUsingPagination } from '../services/assetService'
import { AssetType } from '../enums/AssetType'
import AssetFilters, { AssetFiltersProps } from './AssetFilters'
import EmptyState from './EmptyState'
import { useTheme } from '@mui/system'

interface AssetScrollerProps {
  /**
   * The initial asset query.
   */
  initialQuery?: AssetQuery

  /**
   * The initial view mode.
   */
  initialViewMode?: AssetGridViewMode

  /**
   * The initial statistic.
   */
  initialStatistic?: Statistic

  /**
   * Indicates if filters should be shown.
   */
  showFilters?: boolean

  /**
   * The page size.
   */
  pageSize?: number

  /**
   * Indicates if the scroller should auto fill.
   */
  autoFill?: boolean

  filtersProps?: Pick<
    AssetFiltersProps,
    'forceAccordion' | 'expandIcon' | 'title'
  >
}

export default function AssetScroller({
  initialQuery = {},
  initialViewMode = 'large',
  initialStatistic = 'avg',
  filtersProps,
  showFilters = true,
  pageSize = 24,
  autoFill = true
}: AssetScrollerProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The assets.
   */
  const [assets, setAssets] = useState<Asset[]>([])

  /**
   * The current page.
   */
  const [currentPage, setCurrentPage] = useState<number>(1)

  /**
   * The page count.
   */
  const [pageCount, setPageCount] = useState<number>(1)

  /**
   * The total count.
   */
  const [totalCount, setTotalCount] = useState<number>(0)

  /**
   * Indicates if there are more pages.
   */
  const [hasMorePages, setHasMorePages] = useState<boolean>(true)

  /**
   * Indicates if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  /**
   * Indicates if the component is loading.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * The height of the scroller.
   */
  const [scrollerHeight, setScrollerHeight] = useState<number>(0)

  /**
   * The scroller element.
   */
  const scroller = createRef<HTMLElement>()

  /**
   * Indicates if the assets are empty.
   */
  const isEmpty = !isBooting && assets.length === 0

  /**
   * The asset query.
   */
  const [query, setQuery] = useState<AssetQuery>(initialQuery)

  /**
   * The selected view mode.
   */
  const [viewMode, setSelectedViewMode] =
    useState<AssetGridViewMode>(initialViewMode)

  /**
   * The selected statistic.
   */
  const [statistic, setStatistic] = useState<Statistic>(initialStatistic)

  /**
   * Boots the component.
   *
   * @param signal - The abort signal.
   */
  async function boot(signal: AbortSignal): Promise<void> {
    try {
      setIsBooting(true)
      await loadAssets(signal)
    } catch (error: any) {
      // TODO: Handle boot errors.
      console.error(error)
    } finally {
      setIsBooting(false)
    }
  }

  /**
   * Loads more assets if needed.
   *
   * @param signal - The abort signal.
   */
  async function loadMoreAssetsIfNeeded(signal?: AbortSignal): Promise<void> {
    const pageHeight = document.getElementById('root')?.clientHeight ?? 0

    const threshold = 150
    if (currentPage <= pageCount && scrollerHeight - threshold < pageHeight) {
      await loadAssets(signal)
    }
  }

  /**
   * Load more assets.
   */
  async function loadMoreAssets(): Promise<void> {
    if (currentPage <= pageCount) {
      await loadAssets()
    }
  }

  /**
   * Loads the assets.
   *
   * @param signal - The abort signal.
   */
  async function loadAssets(signal?: AbortSignal): Promise<void> {
    try {
      setIsLoading(true)

      const { pageCount, totalCount, data } = await getAssetsUsingPagination(
        query,
        {
          page: currentPage,
          pageSize
        },
        {
          signal
        }
      )

      setPageCount(pageCount)
      setTotalCount(totalCount)
      setAssets([...assets, ...data])

      setCurrentPage(currentPage + 1)
      if (currentPage >= pageCount) {
        setHasMorePages(false)
      }
    } catch (error: any) {
      // TODO: Handle errors.
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the query change.
   *
   * @param query - The asset query.
   */
  function handleQueryChange(query: AssetQuery): void {
    setQuery(query)
    setCurrentPage(1)
    setAssets([])
  }

  /**
   * Handle the view mode change.
   *
   * @param viewMode - The view mode.
   */
  function handleViewModeChange(viewMode: AssetGridViewMode): void {
    setSelectedViewMode(viewMode)
  }

  /**
   * Handle the statistic change.
   *
   * @param statistic - The statistic.
   */
  function handleStatisticChange(statistic: Statistic): void {
    setStatistic(statistic)
  }

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * Indicates if the screen is mobile.
   */
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  /**
   * Boot the component when current user or query changes.
   */
  useEffect(() => {
    if (currentUser) {
      const controller = new AbortController()
      boot(controller.signal)
      return () => controller.abort()
    }
  }, [currentUser, query])

  /**
   * Load more assets if needed when the current page changes.
   */
  useEffect(() => {
    const controller = new AbortController()

    if (autoFill && currentPage > 1) {
      loadMoreAssetsIfNeeded(controller.signal)
    }

    return () => controller.abort()
  }, [currentPage])

  /**
   * Set the scroller height when mounted.
   */
  useEffect(() => {
    setScrollerHeight(scroller?.current?.clientHeight ?? 0)
  }, [])

  return (
    <Box>
      {showFilters && (
        <Box sx={{ mb: isMobile || filtersProps?.forceAccordion ? 3 : 6 }}>
          <AssetFilters
            query={query}
            viewMode={viewMode}
            statistic={statistic}
            onQueryChange={handleQueryChange}
            onViewModeChange={handleViewModeChange}
            onStatisticChange={handleStatisticChange}
            {...filtersProps}
          />
        </Box>
      )}

      <Box ref={scroller}>
        {isBooting ? (
          <AssetGridSkeleton
            count={pageSize / 4}
            size={viewMode !== 'data' ? viewMode : 'large'}
          />
        ) : isEmpty ? (
          <EmptyState title={t('noAssets')} />
        ) : (
          <InfiniteScroll
            pageStart={currentPage}
            loadMore={loadMoreAssets}
            hasMore={hasMorePages}
            useWindow={false}
            getScrollParent={() => document.getElementById('root')}
            initialLoad={false}
            loader={
              <>
                {isLoading && (
                  <Grid item mt={6}>
                    <AssetGridSkeleton
                      count={pageSize / 4}
                      size={viewMode !== 'data' ? viewMode : 'large'}
                    />
                  </Grid>
                )}
              </>
            }
          >
            <AssetGrid
              assets={assets}
              query={query}
              viewMode={viewMode}
              statistic={statistic}
            />
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  )
}
