import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Outlet } from 'react-router-dom'

import {
  Alert,
  AlertColor,
  Box,
  Collapse,
  CssBaseline,
  Paper as MuiPaper
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { spacing } from '@mui/system'

import GlobalStyle from '../components/GlobalStyle'
import Navbar from '../components/navbar/Navbar'
import dashboardItems from '../components/sidebar/dashboardItems'
import Sidebar from '../components/sidebar/Sidebar'
import Footer from '../components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { createNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'

const drawerWidth = 258

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`

const Paper = styled(MuiPaper)(spacing)

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`

const Manufacturing: React.FC = ({ children }) => {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The theme.
   */
  const theme = useTheme()

  const { notification } = useSelector(
    (state: RootState) => state.notifications
  )

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  /**
   * The severity of the notification.
   */
  const severity = notification?.type as AlertColor

  /**
   * Indicates if the layout is desktop.
   */
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const closeNotification = () => {
    dispatch(
      createNotification({
        show: false,
        type: '',
        message: '',
        timeout: 0
      })
    )
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined

    if (
      notification?.type === NotificationType.SUCCESS ||
      notification?.type === NotificationType.WARNING ||
      notification?.type === NotificationType.INFO
    ) {
      timeout = setTimeout(() => {
        closeNotification()
      }, notification.timeout)
    }

    return () => clearTimeout(timeout)
  }, [notification?.show])

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        {isDesktop ? (
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
          />
        ) : (
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={isSidebarOpen}
            items={dashboardItems}
            onClose={toggleSidebar}
          />
        )}
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={toggleSidebar} />
        <Box
          style={{
            position: 'fixed',
            bottom: '80px',
            right: '16px',
            zIndex: 9999
          }}
        >
          <Collapse in={notification?.show}>
            <Alert
              variant="filled"
              severity={severity}
              onClose={closeNotification}
            >
              {notification?.message}
            </Alert>
          </Collapse>
        </Box>
        <MainContent p={isDesktop ? 12 : 5} sx={{ borderRadius: 0 }}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  )
}

export default Manufacturing
